.values-carousel .swiper-pagination {
  width: fit-content;
  /* background-color: #252525; */
}

.slide {
  position: absolute;
  inset: 0;
  font-size: 2rem;
}

.slide1 {
  opacity: 0;
}

.slide2 {
  opacity: 0;
}

.slide3 {
  opacity: 0;
}

.background-gradient-img {
  position: relative;
  z-index: 1;
}

.background-gradient-img::before {
  content: '';
  background: url(../../assets/globals/bg-circle-top.png);
  mix-blend-mode: color-dodge;
  filter: grayscale(1) contrast(1.2);
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  background-size: 45%, cover;
  background-position: top left;
  background-repeat: no-repeat;
  z-index: -1;
  /* filter: grayscale(1); */
}

.stories-carousel {
  position: relative;
}

.stories-carousel::before {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 60px;
  z-index: 10;
  background: linear-gradient(to right, #000, transparent);
}

.stories-carousel::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  height: 100%;
  width: 60px;
  z-index: 10;
  background: linear-gradient(to left, #000, transparent);
}

@media (max-width: 786px) {
  .stories-carousel::before,
  .stories-carousel::after {
    width: 10px;
  }
}
